const header = () => {
    
    function toggleMenu(e) {
       e && e.preventDefault()
       e && e.stopPropagation()
        if(e?.target.closest('[data-product-menu]')) {
           // console.log('menu clicked')
        if(e.target.hasAttribute('data-product-submenu') || e.target.closest('[data-product-submenu]')) {
            const activeLink = [...document.querySelectorAll('[data-product-submenu]')].find(element => element.classList.contains('js-active'))
           // console.log('active link', activeLink)
            activeLink && activeLink.classList.remove('js-active')
            e.target.closest('[data-product-submenu]').classList.toggle('js-active')
            window.location.href = e.target.closest('[data-product-submenu]').getAttribute('data-product-submenu')
        }
        let isActive = this.classList.toggle('js-submenu-visible')
        this.lastElementChild.style.height = isActive ? (this.lastElementChild.scrollHeight+10) + "px" : "0"
        }
        else  {
            //console.log('this', this)
            let isActive = this.classList.toggle('js-mobile-bar-active')
            if(isActive) {
                document.body.style.overflowY = 'hidden'
                // this.nextElementSibling.style.transform =  `translateY(60px)`
                this.nextElementSibling.style.height = window.innerHeight+ "px"
                // this.nextElementSibling.style.opacity = "1"
                this.nextElementSibling.style.visibility = "visible"
                this.nextElementSibling.style.zIndex = "2"
                // this.nextElementSibling.style.overflow = "unset"
            }
            else {
                const nestedMenu = this.nextElementSibling.querySelector('[data-product-menu]')
                if(nestedMenu.classList.contains('js-submenu-visible')) {
                    nestedMenu.classList.remove('js-submenu-visible')
                    nestedMenu.lastElementChild.style.height = '0'
                }
                document.body.style.overflowY = 'auto'
                // this.nextElementSibling.style.transform = `translateY(${-Math.abs(window.innerHeight)}px)`
                 this.nextElementSibling.style.height = "0"
                //  this.nextElementSibling.style.opacity = "0"
                this.nextElementSibling.style.visibility = "hidden"
                // this.nextElementSibling.style.overflow = "hidden"
            }
        }
    }
    const productsMenu = document.querySelector('[data-product-menu]')
    const mobileMenu = document.querySelector('[data-mobile-menu]')
    //console.log(productsMenu);

    productsMenu.addEventListener('click', toggleMenu)
    mobileMenu.addEventListener('click', toggleMenu)

    window.addEventListener('click', function(e) {
        //console.log(e.target)
        if(!e.target.closest('[data-product-menu]')) {
           // console.log('inside if condition',e.target.closest('[data-product-menu]'))
            const submenu = document.querySelector('[data-product-menu]')
            submenu.classList.remove('js-submenu-visible')
           // console.log(submenu)
            submenu.lastElementChild.style.height = "0"
            
        }
    })

    // 

var header = document.querySelector(".header");
var navbarHeight = header.offsetHeight;
var lastScrollTop = 0;

window.onscroll = function() {scrollHide()};

function scrollHide() {
  var st = window.pageYOffset || document.documentElement.scrollTop;
  //console.log(st);
  if (st > lastScrollTop) {
    header.classList.add('hide');
  } else {
    header.classList.remove('hide');
  }
  lastScrollTop = st <= 0 ? 0 : st;
}
    // 
}

export default header